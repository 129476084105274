
























































































































import { Component, Vue } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import { I18nService } from '@cds/i18n';
import { searchConfig } from './operationhistory.config';
import { ISearchConfig } from '@/components/SearchTable/index.d';
import { AuditService } from '@/services/audit-service';
import { UserUploadService } from '@/services/user-uploading';
import { cloneDeep, isArray } from 'lodash';

@Component
export default class Consent extends Vue {
  public searchFormLoading: boolean = false;
  public loading: boolean = false;
  // i18n
  @Inject(I18nService) private i18nSvc!: I18nService;
  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }

  @Inject(AuditService) private auditService!: AuditService;
  @Inject(UserUploadService) private userUploadService!: UserUploadService;

  private form: any = {
    // userName: '',
    // employeeId: '',
    // email: '',
    // division: [],
    // charactor: [],
    // module: [],
    // action: [],
    // result: [],
    // effectiveTime: ''
  };
  private pagination: any = {
    pageSize: 10,
    total: 0,
    pageNum: 1
  };
  private searchConfig: ISearchConfig[] = [];
  private tableData: Dict<any> = [];
  private eventList: any[] = [];

  private async getList(val?: any, type?: string) {
    let form: any = { currentPage: 1, pageSize: 10 };
    if (type === 'pagination') {
      const { pageSize, pageNum } = val;
      form.currentPage = pageNum;
      form.pageSize = pageSize;
      form = Object.assign({}, { currentPage: pageNum, pageSize }, this.form);
    } else if (type === 'search') {
      form = Object.assign(form, val);
    }
    console.log(form);
    if (form.department) {
      form.department =
        form.department.length > 0 ? form.department.join(',') : '';
    }
    if (form.effectiveTime && isArray(form.effectiveTime)) {
      form.timeFrom = form.effectiveTime[0];
      form.timeTo = form.effectiveTime[1];
      delete form.effectiveTime;
    }
    try {
      this.loading = true;
      const res = await this.auditService.getList(form);
      console.log(res);
      if (!res) {
        this.$notify.error({
          title: '错误',
          message: '获取列表失败，请您重试。'
        });
        return;
      }
      const { records, current, total, size } = res;
      this.pagination.pageSize = size;
      this.pagination.total = total;
      this.pagination.pageNum = current;
      if (records && isArray(records)) {
        this.tableData = records;
        console.log(this.tableData[0].result);
      }
    } finally {
      this.loading = false;
    }
  }

  private async initData() {
    this.searchConfig = cloneDeep(searchConfig);
    try {
      this.searchFormLoading = true;
      // this.searchConfig[3].options =
      //   (await this.auditService.getdivision()) || [];
      this.searchConfig[4].options =
        (await this.auditService.getprofilelist()) || [];
      this.searchConfig[5].options =
        (await this.auditService.getmodule()) || [];
      this.searchConfig[6].options =
        (await this.auditService.getaction()) || [];
      this.searchConfig[7].options =
        (await this.auditService.getresult()) || [];
      await this.getList();
    } finally {
      this.searchFormLoading = false;
    }
  }

  private mounted(): void {
    this.initData();
  }

  private selectChange(val: Dict<any>): void {
    this.form = val;
  }
}
