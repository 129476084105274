import { ISearchConfig } from '@/components/SearchTable/index.d';

export const DEPAETMENT = [
  { label: 'PHARMA', value: 'PHARMA' }
];

export const STATUSLIST = [
  { label: '草稿', value: 'DRAFT' },
  { label: '待生效', value: 'PUBLISHED' },
  { label: '生效', value: 'EFFECTIVE' },
  { label: '已失效', value: 'EXPIRED' }
];

export enum STATUSENUM {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  EFFECTIVE = 'EFFECTIVE',
  EXPIRED = 'EXPIRED'
}

export const searchConfig: ISearchConfig[] = [
  {
    category: 'common_content',
    subclass: 'userName',
    type: 'input',
    key: 'userName'
  },
  {
    category: 'common_content',
    subclass: 'id',
    type: 'input',
    key: 'employeeId'
  },
  {
    category: 'common_content',
    subclass: 'email',
    type: 'input',
    key: 'email'
  },
  {
    category: 'common_content',
    subclass: 'division',
    type: 'select',
    key: 'division',
    multiple: true,
    options: DEPAETMENT
  },
  {
    category: 'common_content',
    subclass: 'role',
    type: 'select',
    key: 'charactor',
    multiple: true,
    options: []
  },
  {
    category: 'common_content',
    subclass: 'function_module',
    type: 'select',
    key: 'module',
    multiple: true,
    options: []
  },
  {
    category: 'common_content',
    subclass: 'operational_motion',
    type: 'select',
    key: 'action',
    options: []
  },
  {
    category: 'common_content',
    subclass: 'operational_result',
    type: 'select',
    key: 'result',
    options: []
  },
  {
    category: 'common_content',
    subclass: 'operational_time',
    extendType: 'datetimerange',
    type: 'datepicker',
    key: 'effectiveTime',
    valueformat: 'yyyy-MM-dd HH:mm:ss'
  }
];

export enum StatusCode {
  DRAFT = '草稿',
  PUBLISHED = '待生效',
  EFFECTIVE = '生效',
  EXPIRED = '已失效'
}
