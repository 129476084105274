import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';

export class UserUploadService {
  [key: string]: any;

  public value: any;

  @Inject(HttpService) private http!: HttpService;

  public setVal(val: any) {
    this.value = val;
  }

  public getVal() {
    return this.value;
  }

  public getuserpharmatree(): Promise<any> {
    return this.http.get('/api/user/uploading/field-force-org/pharma/tree');
    // return this.http.get('/api/hr/dept/tree');
  }

  // 通讯录tree的数据。
  public getaddresslisttree(): Promise<any> {
    return this.http.get('/api/user/uploading/field-force-org/pharma/tree');
    // return this.http.get('/api/hr/dept/tree');
  }

  public getuseroncotree(): Promise<any> {
    return this.http.get('/api/user/uploading/field-force-org/onco/tree');
  }

  public getuserpharma(param: Dict<string>): Promise<any> {
    return this.http.get('/api/user/uploading/field-force-org/pharma', param);
    // return this.http.get('/api/hr/dept/user', param);
  }

  public getuseronco(param: Dict<string>): Promise<any> {
    return this.http.get('/api/user/uploading/field-force-org/onco', param);
  }

  public getuploadstatus(type: string): Promise<any> {
    return this.http.get(
      `/api/user/uploading/field-force-org/upload-status/${type}`
    );
  }
  // 获取部门树
  public getDepartmentTree(): Promise<any> {
    return this.http.get('/api/hr/dept/tree');
  }

  // 获取该部门所有的人员名单
  public getdepartmentPeople(param: Dict<string>): Promise<any> {
    return this.http.post('/api/hr/dept/user', param);
  }

  // 通讯录tree通过入参过滤部门名称
  public gettreeDepartmentname(param: Dict<string>): Promise<any> {
    return this.http.get('/api/hr/dept/name', param);
  }
  // 通讯录form表单里面的division数据
  public getDepartmentdivision(): Promise<any> {
    return this.http.get('/api/hr/division/check');
  }
  //hrCode列表查询接口
  public getPeopleList(param: Dict<any>): Promise<any>{
    return this.http.get('/api/hr/logList', param);
  }

  //hrCode列表批次处理中完成状态
  // public getUpdateState(param: Dict<any>): Promise<any>{
  //   return this.http.get('/api/hr/log/updateTime', param);
  // }

  // 通讯录获取更新时间
  public getupdateTime(): Promise<any> {
    return this.http.get('/api/hr/log/updateTime');
  }

  // SFDC日志pharma接口
  public getsfdcPharma(param: Dict<string>): Promise<any> {
    return this.http.post('/api/sfdc/log/pharma',param);
  }

  // SFDC日志oncology接口
  public getsfdcOncology(param: Dict<string>): Promise<any> {
    return this.http.post('/api/sfdc/log/onco',param);
  }

  // SFDC日志获取是否开启同步接口
  public getsynchronization(param: Dict<string>): Promise<any> {
    return this.http.get('/api/sfdc/log/qrySwitchStatus',param);
  }
  //SFDC日志获取同步时间和当前同步状态
  public getsyncTime(param: Dict<string>): Promise<any> {
    return this.http.get('/api/sfdc/log/');
  }

  // SFDC日志是否开启同步接口,返回是否开启状态
  public synchronization(param: Dict<string>): Promise<any> {
    return this.http.post('/api/sfdc/log/switch',param);
  }

  public getuploadsTable(param: Dict<string>): Promise<any> {
    return this.http.post('/api/user/uploading/field-force-org/table', param);
  }

  public getpromotionGridsList(type: string): Promise<any> {
    return this.http.get(
      `/api/user/uploading/field-force-org/promotionGrids/${type}`
    );
  }

  public getprofilelist(): Promise<any> {
    return this.http.get('/api/user/uploading/field-force-org/profilelist');
  }

  public uploadstafflist(param: Dict<any>, division: string): Promise<any> {
    let config: any = {
      headers: { 'Content-Type': 'multipart/form-data' }
    };
    let queryString: any = {};
    return this.http.put(
      `/api/user/uploading/excel?division=${division}`,
      param,
      queryString,
      config
    );
  }

  public async downstafflist(id: string, name?: string): Promise<any> {
    return this.http.downloadFile(`/api/user/uploading/${id}`, {}, name);
  }

  public async downstaffTemp(type: string, name?: string): Promise<any> {
    return this.http.downloadFile(
      `/api/user/uploading/downLoad-template/${type}`,
      {},
      name
    );
  }

  // 通讯录点击下载表格数据接口
  public async addressbookdownload(type: string, param: Dict<string>, name?: string): Promise<any> {
    return this.http.downloadFiles(
      `/api/hr/download/${type}`,
      {},
      param,
      name
    );
  }

  public async getApplicationNames(param: Dict<string>): Promise<any> {
    return this.http.get('/api/channels/profile/list', param);
  }

  public getFileNamePrefix(divisionCode: string): string {
    const names = ['Pharma Field Force', 'ONCO Field Force','HrCore Field Force'];
    return names[Number(divisionCode)];
  }
}
