import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';

export class AuditService {
  @Inject(HttpService) private http!: HttpService;

  public getList(params: Dict<any>): Promise<any> {
    return this.http.get('/api/audit/page', params);
  }

  public getaction(): Promise<any> {
    return this.http.get('/api/audit/action');
  }

  public getdivision(): Promise<any> {
    return this.http.get('/api/audit/division');
  }

  public getmodule(): Promise<any> {
    return this.http.get('/api/audit/module');
  }

  public getresult(): Promise<any> {
    return this.http.get('/api/audit/result');
  }
  public getprofilelist(): Promise<any> {
    return this.http.get('/api/audit/profilelist');
  }
}
